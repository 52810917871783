export default [
	{
		_name: "CSidebarNav",
		_children: [
			{
				_name: "CSidebarNavItem",
				name: "Dashboard",
				to: "/dashboard",
				permission: "all_access",
				icon: "cil-speedometer",
				badge: {
					color: "primary",
					text: "NEW",
				},
			},
			{
				_name: "CSidebarNavDropdown",
				name: "Masters",
				route: "master/product",
				permission: "master_access",
				icon: "cil-puzzle",
				items: [
					{
						name: "Product",
						permission: "all_access",
						to: "/master/product",
					},
					{
						name: "Customer",
						permission: "all_access",
						to: "/master/customer",
					},
					{
						name: "User",
						permission: "user_access",
						to: "/master/user",
					},
				],
			},

			{
				_name: "CSidebarNavTitle",
				permission: "all_access",
				_children: ["TRANSACTIONS"],
			},

			{
				_name: "CSidebarNavDropdown",
				name: "Purchase",
				route: "/po",
				permission: "all_access",
				icon: "cil-pencil",
				items: [
					{
						name: "Form",
						permission: "all_access",
						to: "/po/form",
					},
					{
						name: "List",
						permission: "all_access",
						to: "/po",
					},
					{
						name: "Print By PO",
						permission: "all_access",
						to: "/qrcode/print-by-po",
					},
				],
			},

			{
				_name: "CSidebarNavDropdown",
				name: "Order",
				route: "/order",
				permission: "all_access",
				icon: "cil-pencil",
				_class: "bg-success text-white",
				items: [
					{
						name: "Stock",
						permission: "all_access",
						to: "/order/stock",
					},
					{
						name: "Form",
						permission: "all_access",
						to: "/order/form",
					},
					{
						name: "List",
						permission: "all_access",
						to: "/order",
					},
					{
						name: "Cari by Product",
						permission: "all_access",
						to: "/report/search-by-product",
					},
				],
			},
			{
				_name: "CSidebarNavItem",
				name: "Penyesuaian",
				to: "/adjustment",
				permission: "penyesuaian_access",
				icon: "cil-pencil",
			},

			{
				_name: "CSidebarNavDropdown",
				name: "ACL",
				route: "/role",
				permission: "acl_access",
				icon: "cil-puzzle",
				items: [
					{
						name: "Role",
						permission: "all_access",
						to: "/role",
					},
					{
						name: "Access",
						permission: "all_access",
						to: "/access",
					},
					{
						name: "Permission",
						permission: "all_access",
						to: "/permission",
					},
				],
			},
		],
	},
];
